import React, {useEffect, useRef, useState} from "react";
import headerLogo from "assets/FordOval-white.svg";
import fmaFunctions from "../../../services/fmaFunctions";
import {useAmplitude} from "useAmplitude/src/useAmplitude";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import userIcon from "../../../assets/userProfile.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderCss from "./Header.module.css";
import {ENROLLED, INVITED, WAITLISTED} from "../../../Constants/Status";

const Header = (props) => {
    let userVehicles = props?.user?.userVehicles || []
    let anyVehiclePartOfEAP = userVehicles.filter((items=>(items.status !== ENROLLED && items.status !== WAITLISTED && items.status !== INVITED)))

    const pathName = window.location.pathname;
    const {logger} = useAmplitude();
    const [showDesktopOverlay, setShowDesktopOverlay] = useState(false)
    const [showCloseMenu, setShowCloseMenu] = useState(false)
    const [showMobileOverlay, setShowMobileOverlay] = useState(false)

    const node = useRef();

    const history = useHistory();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [])

    const login = () => {
        logger("join today tapped", {
            buttonLocation: "header",
            screenName: "landing page",
            previousUrl: document.referrer,
        }).then();
        fmaFunctions().login();
    };

    const logout = () => {
        logger("sign out tapped", {
            screenName: "account",
            previousUrl: document.referrer,
        }).then();

        fmaFunctions().logout();
    };

    const handleSignIn = ()=>{
        if (pathName === "/" && (window.fma.isAuthenticated === false)) login();
        else logout();
    }

    const userName = props.user?.userProfile?.firstName;

    const accountPage = () => {
        history.push("/account")
    }

    const contactUsPage = ()=>{
        history.push("/contactus")
    }

    const menuToggle = ()=>{
        setShowCloseMenu(!showCloseMenu);
        if(showMobileOverlay && showCloseMenu) setShowMobileOverlay(false)
        if(!showCloseMenu)setShowMobileOverlay(true)
        setShowDesktopOverlay(false)
    }

    const menuToggleDesktop = ()=>{
        setShowDesktopOverlay(!showDesktopOverlay)
        setShowMobileOverlay(false)
    }

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        setShowMobileOverlay(false)
        setShowDesktopOverlay(false)
    };

    const isEnrolledEAP = props.user?.userProfile?.isEapUser;


    return (

        <header >
            {showMobileOverlay ? <div className={HeaderCss.mainNavigationContainerOverlayOpen}/> : <div className={HeaderCss.mainNavigationContainerOverlay}/>}
            {showDesktopOverlay ? <div className={HeaderCss.mainNavigationContainerOverlayOpen}/> : <div className={HeaderCss.mainNavigationContainerOverlay}/>}

            <Navbar ref={node}
                    variant="dark"
                    expand="lg"
                    className={HeaderCss.navBarContainer}>
                <Container
                    className={HeaderCss.mainNavigationContainer}>

                    <Navbar.Brand href="/">
                        <div  className={HeaderCss.headerFordLogo}>

                            <div>
                                <img
                                src={headerLogo}
                                onClick={() => {
                                    history.push("/")
                                }}
                                alt="Ford Naval Logo"
                            />
                            </div>
                            <span className={HeaderCss.logoTextSeparator}/>
                            <div data-testid={"logoText"} className={HeaderCss.logoText}> Early Access Program</div>

                        </div>
                    </Navbar.Brand>

                    <div className={HeaderCss.mobileMenuContainer} style={{lineHeight:"3px", marginBottom:"5px", marginRight:"15px"}}>
                        <Navbar.Toggle  id="navbar-toggle"
                                        data-testid = "navbar-toggle"
                                        className={showCloseMenu ? HeaderCss.toggleCloseMenu : HeaderCss.toggleMenu}
                                        onClick={menuToggle}
                                        style={{lineHeight:"3px", marginBottom:"5px", marginRight:"15px"}}
                                        aria-controls="basic-navbar-nav"/>
                        {!showCloseMenu ? <span
                            data-testid = "mobileMenu"
                            className={HeaderCss.mobileMenuText} >Menu</span> : <span className={HeaderCss.mobileMenuText} />}

                    </div>{showCloseMenu && <div
                    className={HeaderCss.topHeaderDividerContainer}><hr className={HeaderCss.topHeaderDivider}/></div>}

                    <Navbar.Collapse  style={{marginRight:"20px"}} id="basic-navbar-nav">

                        <Nav  className="ml-auto" >
                            <div style={{display:"flex"}}>
                                  <span className={HeaderCss.profileIcon}>
                                  <img src={userIcon} alt="userIcon"/>
                                  </span>
                                <NavDropdown

                                    data-testid={"myAccountBtn"}
                                    onClick={menuToggleDesktop}
                                    className={HeaderCss.myAccountContainer}
                                    title="MY ACCOUNT"
                                    data-cy="navbar-menu"
                                    id="basic-nav-dropdown">


                                    <div className={HeaderCss.innerDropDown}>
                                        {(window.fma.isAuthenticated === true)  &&  userVehicles.length !== anyVehiclePartOfEAP.length && <NavDropdown.Item  onClick={accountPage}>
                                            <p className={HeaderCss.accountInner}>{userName}'s Account
                                                <span className="fds-icon"><span className="fds-font--ford-icons__chevron-right"/></span>
                                            </p>  </NavDropdown.Item>}


                                        {isEnrolledEAP ==="active" && userVehicles.length !== anyVehiclePartOfEAP.length && <NavDropdown.Divider className={HeaderCss.dropdownItemDivider}/>}

                                        {isEnrolledEAP ==="active" && <NavDropdown.Item
                                            data-testid={"contactUsBtn"}
                                            onClick={contactUsPage}>

                                            <p className={HeaderCss.accountInner}>Support
                                                <span className="fds-icon"><span
                                                    className="fds-font--ford-icons__chevron-right"/></span>
                                            </p>
                                        </NavDropdown.Item>}

                                        {window.fma.isAuthenticated === true  && userVehicles.length !== anyVehiclePartOfEAP.length && <NavDropdown.Divider className={HeaderCss.dropdownItemDivider}/>}

                                        {(window.fma.isAuthenticated === false || window.fma.isAuthenticated === undefined) ? <NavDropdown.Item
                                            onClick={handleSignIn}>

                                            <p className={HeaderCss.accountInner}>Sign In
                                                <span className="fds-icon"><span className="fds-font--ford-icons__chevron-right"/></span>
                                            </p>
                                        </NavDropdown.Item> : <NavDropdown.Item onClick={logout}>
                                            <p className={HeaderCss.accountInner}>Sign out
                                                <span className="fds-icon"><span className="fds-font--ford-icons__chevron-right"/></span>
                                            </p></NavDropdown.Item>}

                                    </div>

                                </NavDropdown>
                            </div>


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};

export default connect(mapStateToProps)(Header);
